class VerificationCarModel {
  readonly id:number;
  readonly carId:string;
  readonly verificationStatus:string;
  readonly brand:string;
  readonly model:string;
  readonly year:string;
  readonly isReviewed:boolean;
  readonly createdOn:string;
  constructor({
    id,
    carId,
    verificationStatus,
    brand,
    model,
    year,
    isReviewed,
    createdOn,
  }) {
    this.id=id;
    this.carId=carId;
    this.verificationStatus=verificationStatus
    this.brand=brand;
    this.model=model;
    this.year=year;
    this.isReviewed=isReviewed;
    this.createdOn=createdOn;
  }
}

export default VerificationCarModel;
