class VerificationDealModel {
  readonly createdOn: string;
  readonly id:number;
  readonly clientId:string;
  readonly dealId:string;
  readonly verificationStatus:string;
  readonly isReviewed:boolean;
  readonly fio:string;
  constructor({
    createdOn,
    id,
    clientId,
    dealId,
    verificationStatus,
    isReviewed,
    fio,
  }) {
    this.createdOn = createdOn;
    this.id=id;
    this.clientId=clientId;
    this.verificationStatus=verificationStatus;
    this.dealId=dealId;
    this.isReviewed=isReviewed;
    this.fio=fio;
  }
}

export default VerificationDealModel;
