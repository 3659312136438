/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, useMemo } from 'react';
import { Avatar, FormGroup, FormLabel, TextField } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tooltip, IconButton, Fab } from '@material-ui/core';
import { AddCircle, Delete, AddAPhoto } from '@material-ui/icons';
import * as style from '@material-ui/core/styles';
import { OPTIONS } from './utils';
import { PopularCities, PlaceDetails } from '../../services/api';
import useQuery from '../../hooks/useQuery';
import createPopularCitiesFromRowData from 'models/modelFactory/popular-city';
import { ajaxAction } from 'services';

const useStyles = style.makeStyles((theme: style.Theme) =>
  style.createStyles({
    input: {
      display: 'none'
    },
    button: {}
  })
);

const PopularCitiesComponent: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const { getPopularCitiesAll, deletePopularCityById,changeImage } = PopularCities();
  const { getPlaceDetails } = PlaceDetails();
  const history = useHistory();
  const query = useQuery();
  const handleUploadClick = async (event: React.ChangeEvent<HTMLInputElement>,tableMeta:any) => {
    const files = event.target.files;
    if (files) {
      const file: any = files[0];
      if (file) {
        const url = process.env.REACT_APP_HOST_HOST_API + 'popular-city/image/upload';
        const xForm = new FormData();
        xForm.append('File', file);
        const method = 'POST';
        const resp: any = await ajaxAction(url, method, xForm);
        const response = await changeImage({popularCityId:tableMeta.rowData[3],imageUrl:resp.data.imageUrl});
        window.location.reload()
        //   const urlUpload = process.env.REACT_APP_HOST_HOST_API + 'popular-city/image';
      //   const xFormAdd=new FormData();
      //   xFormAdd.append("PopularCityId",tableMeta.rowData[3]);
      //   xFormAdd.append("File",file)
      // const methodAdd = 'PATCH';
      // const responce: any = await ajaxAction(urlUpload, methodAdd, xFormAdd);
      
      }
    }
  };
  const GET_COLUMN = (filtersFromUrl: any) => [
    {
      name: 'city',
      label: t('city'),
      options: {
        filter: true
      }
    },
    {
      name: 'createdOn',
      label: t('date_of_creation'),
      options: {
        filter: true,
        sort: true,
        filterType: 'custom' as any,
        customFilterListOptions: {
          render: (v) => {
            if (v[0] && v[1]) {
              return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
            } else if (v[0]) {
              return `${t('from')}: ${v[0]}`;
            } else if (v[1]) {
              return `${t('to')}: ${v[1]}`;
            }
            return false;
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          }
        },
        filterList: (() => {
          const list: any = [];
          if (filtersFromUrl.createdOnStart) {
            list[0] = filtersFromUrl.createdOnStart;
          }
          if (filtersFromUrl.createdOnEnd) {
            list[1] = filtersFromUrl.createdOnEnd;
          }
          return list;
        })(),
        //sortDirection: 'desc',
        sortCompare: (order: string) => {
          return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
            const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
            const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
            return val1.diff(val2) * (order === 'asc' ? -1 : 1);
          };
        },
        filterOptions: {
          names: [],
          logic(date: moment.MomentInput, filters: any[]): boolean {
            const check: any = moment(date, 'DD.MM.YYYY HH:mm');
            const from: any = moment(filters[0], 'YYYY-MM-DD');
            const to: any = moment(filters[1], 'YYYY-MM-DD');
            if (
              filters[0] &&
              filters[1] &&
              check.diff(to, 'days') > 0 &&
              check.diff(from, 'days') < 0
            ) {
              return true;
            } else if (filters[1] && check.diff(to, 'days') > 0) {
              return true;
            } else if (filters[0] && check.diff(from, 'days') < 0) {
              return true;
            }
            return false;
          },
          display: (
            filterList: { [x: string]: any },
            onChange: (arg0: any, arg1: any, arg2: any) => void,
            index: string | number,
            column: any
          ): JSX.Element => (
            <div>
              <FormLabel style={{ fontSize: 15 }}>{t('date_of_creation')}</FormLabel>
              <FormGroup row>
                <TextField
                  id='startDate'
                  label={t('start_date')}
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][0] || ''}
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
                />
                <TextField
                  id='endDate'
                  label={t('end_date')}
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={filterList[index][1] || ''}
                  onChange={(event) => {
                    filterList[index][1] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
                />
              </FormGroup>
            </div>
          )
        }
      }
    },
    {
      name: 'imageUrl',
      label: t('image'),
      options: {
        customBodyRender: (value,tableMeta,) => {
          return value ? (
            <Avatar variant='rounded' src={value}></Avatar>
          ) : (
            <>
              <input
                accept='image/*'
                className={classes.input}
                id='contained-button-file'
                multiple
                type='file'
                onChange={(event)=>handleUploadClick(event,tableMeta)}
              />
              <label htmlFor='contained-button-file'>
                <Fab size='small' component='span' className={classes.button}>
                  <AddAPhoto />{' '}
                </Fab>
              </label>
            </>
          );
        },
        filter: false
      }
    },
    {
      name: 'id',
      label: t('delete'),
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              onClick={async () => {
                const answer = window.confirm('Do you want delete this popular city?');
                if (answer) {
                  await deletePopularCityById(value);
                  window.location.reload();
                }
              }}>
              <Delete />
            </IconButton>
          );
        }
      }
    }
  ];
  useEffect(() => {
    (async () => {
      const popularCityAll = await getPopularCitiesAll();
      const parsedData = popularCityAll ? createPopularCitiesFromRowData(popularCityAll) : [];
      const locationData = await Promise.all(parsedData.map(city => getPlaceDetails(city.city)));
      const localizedData = parsedData.map(city => {
        const place = locationData.find(item => item.placeId === city.city)
        return {
          ...city,
          city: place.fullAddress,
        }
      })
      setData(localizedData);
    })();
  }, []);

  const onChangePage = (currentPage: number) => {
    query.set('page', (currentPage + 1).toString());
    window.history.pushState(null, '', `?${query.toString()}`);
  };

  const queryObject: any = query.getObject();

  const checkPageSize = (object) => {
    return object?.pageSize;
  };

  const column = useMemo(() => GET_COLUMN(queryObject), []);
  const pageSize = useMemo(() => checkPageSize(queryObject), []);

  const AddButton = () => (
    <Tooltip disableFocusListener title='Add City'>
      <IconButton
        onClick={() => {
          history.push(`addPopularCity`);
        }}>
        <AddCircle />
      </IconButton>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
        <div style={{ width: '90%' }}>
          <MUIDataTable
            title={t('popular_cities')}
            data={data}
            columns={column}
            options={{
              customToolbar: AddButton,

              ...OPTIONS(t),
              onChangeRowsPerPage: (numberOfRows) => {
                query.set('pageSize', numberOfRows.toString());
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              rowsPerPage: pageSize || 10,
              onFilterChange(changedColumn: string, filterList, type, changedColumnIndex) {
                switch (changedColumn) {
                  case 'createdOn': {
                    query.delete('createdOnStart');
                    query.delete('createdOnEnd');

                    if (filterList[changedColumnIndex][0]) {
                      query.set('createdOnStart', filterList[changedColumnIndex][0]);
                    }

                    if (filterList[changedColumnIndex][1]) {
                      query.set('createdOnEnd', filterList[changedColumnIndex][1]);
                    }
                    break;
                  }

                  default:
                    if (changedColumn) {
                      query.delete(changedColumn);
                      if (filterList[changedColumnIndex][0]) {
                        query.set(changedColumn, filterList[changedColumnIndex][0]);
                      }
                    }
                    break;
                }
                window.history.pushState(null, '', `?${query.toString()}`);
              },
              page: parseInt(queryObject.page) || 0,
              onChangePage,
              pagination: data.length > 10
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopularCitiesComponent;
