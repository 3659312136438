/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { ProfileClient } from 'services/api'
import { useTranslation } from "react-i18next";

import { ListItemText, MenuItem, ListItemIcon,Select } from '@material-ui/core';
import USD from "../assets/images/usd.png"
import RUB from "../assets/images/rub.png"
import AED from "../assets/images/aed.png"
import EUR from "../assets/images/eur.png"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'transparent',
        },
    },

})

const getCurrencyCode = (value: string): number => {
    switch (value) {
        case 'usd': return 0;
        case 'rub': return 1;
        case 'eur': return 2;
        case 'aed': return 3;
        default: return 0;
    }
}

export function Currency(): JSX.Element {
    const currency = localStorage.getItem('currency')??'usd';
    const { getProfileDefaults, changeProfileCurrency } = ProfileClient();
    const { t } = useTranslation();
    const optionValues = [
        {
            title: t("RUB"),
            icon: RUB,
            short_name: "rub"
        },
        {
            title: t("USD"),
            icon: USD,
            short_name: "usd"
        },
        {
            title: t("EUR"),
            icon: EUR,
            short_name: "eur"
        },
        {
            title: t("AED"),
            icon: AED,
            short_name: "aed"
        },
    ];
    const [value, setValue] = useState<string>(currency);
    const [profile, setProfile] = useState({id: '', currency: value});
    

    const checkValue=()=>{
        return optionValues.find((element:any)=>element.short_name===value)
    }
    const classes = useStyles()

    useEffect (() => {
        (async () => {
            const defaultProfile = await getProfileDefaults();
            setProfile(defaultProfile);
            setValue(profile.currency);            
        })();
    }, [value])

    return (
        <div>
            <Select
                style={{ backgroundColor: "#3f51b5",borderWidth:"5px",borderColor:"red"}}
                value={checkValue()?.short_name}
                className={classes.select}
                renderValue={() => {
                    return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={checkValue()?.icon} alt='something' height={'30'} width={"30"} style={{marginRight:"10px"}} />
                        <ListItemText
                            color={"white"}
                            primary={<p style={{ color: "white" }}>{checkValue()?.title}</p>}
                        />
                    </div>
                    );
                }}
                onChange={async (event:any) => {
                    setValue(event.target.value);
                    await changeProfileCurrency({id: profile.id, currency: getCurrencyCode(event.target.value)});
                    localStorage.setItem('currency', event.target.value);
                    window.location.reload();
                }}
            >
                {optionValues.map(item => (
                    <MenuItem key={item.title} value={item.short_name}>
                        <ListItemIcon>
                            <img src={item.icon} alt='something' height={'30'} width={"30"} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.title}
                        />
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}


