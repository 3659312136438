/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem, ListItemIcon, IconButton, ListItemText } from '@material-ui/core';

const NavItem = (props: { to: any;name: any; navIcon: any; linkType: any; onClick?: any; }): JSX.Element => {
  const { to, name, navIcon: NavIcon, linkType, onClick } = props;

  const content = (
    <>
      <ListItemIcon>
        <IconButton color="primary" aria-label="upload picture" component="span">
          <NavIcon />
        </IconButton>
      </ListItemIcon>
      <ListItemText primary={name} />
    </>
  );

  return linkType ? (
    <ListItem button component={Link} to={to} onClick={onClick}>
      {content}
    </ListItem>
  ) : (
    <ListItem button onClick={onClick}>
      {content}
    </ListItem>
  );
};

export default NavItem;
