/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, FC, } from 'react';
import {  RatingApi, UserClient } from '../../services/api';
import ReviewComponent from 'components/UserDetail/components/reviews/Reviews';
import { createReviewsFromData } from 'components/UserDetail/utils';
import { useLocation} from 'react-router-dom'


const FeedBackComponent: FC = () => {
  const [reviews, setReviews] = useState<any>([]);
  const [totalRaws, setTotalRaws] = useState<number>(0);
  const [listChanged, setListChanged] = useState<boolean>(false);
  const [description, setDescription] = useState<string>();
  const [rate, setRate] = useState<number>();
  const [createdOnFrom, setCreatedOnFrom] = useState<number>();
  const [createdOnTo, setCreatedOnTo] = useState<number>();
  const [fromUser, setFromUser] = useState<string>();
  const [toUser, setToUser] = useState<string>();
  const { getPaginatedRatings, changeRating, deleteRating } = RatingApi();
  const { getUserById } = UserClient();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const page = parseInt(queryParams.get('page') as string) || 1;
  const pageSize = parseInt(queryParams.get('pageSize') as string) || 10;

  useEffect(() => {
    (async () => {
      const { ratings, pagination } = await getPaginatedRatings(page, pageSize, null, {
        description, rate, createdOnFrom, createdOnTo, fromUser, toUser
      });
      setTotalRaws(pagination.total);
      const reviews = await Promise.all(createReviewsFromData(ratings).map(async review => {
        const userFrom = await getUserById(review.fromUserId);
        const userTo = await getUserById(review.toUserId);
        return {
          ...review,
          userFrom: getUserFullName(userFrom),
          userTo: getUserFullName(userTo)
        }
      }))
      setReviews(reviews);
    })();
  }, [page, pageSize, listChanged, description, rate, createdOnFrom, createdOnTo, fromUser, toUser]);

  const changeReview = async (data: any) => await changeRating(data) && setListChanged(!listChanged);

  const deleteReview = async (id: number) => await deleteRating(id) && setListChanged(!listChanged);

  const getUserFullName = (user: any) => `${user?.firstName??''} ${user?.secondName??''} ${user?.thirdName??''}`.trim();

  return (
    <React.Fragment>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '30px 0' }}>

      <ReviewComponent 
      data={reviews}
      changeReview={changeReview}
      deleteReview={deleteReview}
      paginationData={
        {
          page: page,
          pageSize: pageSize,
          totalRaws: totalRaws,
        }
      }
      filterData={
        {
          setDescription,
          setRate,
          setCreatedOnFrom,
          setCreatedOnTo,
          setFromUser,
          setToUser
        }
      }
      />
      </div>
    </React.Fragment>
  );
};

export default FeedBackComponent;
