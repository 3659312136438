/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@material-ui/core';
import axios from 'axios';
import {
  COLUMN,
  COLUMN_POINTS,
  COMLUMNOFFERS,
  createDealDetail,
  createOffers,
  GET_COLUMN,
  OPTIONS,
} from './utils';
import { RatingApi, TripClient, PlaceDetails, ProfileClient } from '../../services/api';
import { AppContextType, useApp } from '../../context/AppContext';
import ReviewComponent from 'components/UserDetail/components/reviews/Reviews';
import { createReviewsFromData } from 'components/UserDetail/utils';
import { getVerificationStatus } from 'models/util';

const DealDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const { changeRating,deleteRating } = RatingApi();

  const history = useHistory();
  const { id } = useParams();
  const { getDealById, getDealInventoryById ,getReviewsByDealId} = TripClient();
  const { getProfileDefaults } = ProfileClient();
  const { getPlaceDetails } = PlaceDetails();
  const { baggages } = useApp() as AppContextType;
  const [inventory, setInventory] = useState([]);

  const [data, setData] = useState<any[]>([]);
  const [offers, setOffers] = useState([]);
  const [value, setValue] = React.useState('1');
  const [industrialPoints,setIndustrialPoints]=useState<any[]>([]);
  const [reviews, setReviews] = useState([]);
  const [carId,setCarId]=useState('');
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const getWayPointByPlaceIds=(detailDeal)=>{
    return Promise.all(
      detailDeal?.wayPointPlaceIds.map(element => getPlaceDetails(element))
    );
  }

  useEffect(() => {
    (async () => {
      const detailDeal = await getDealById(id);
      const profile = await getProfileDefaults();
      const locationFrom = await getPlaceDetails(detailDeal.locationFromPlaceId);
      const locationTo = await getPlaceDetails(detailDeal.locationToPlaceId);
      Promise.all([locationFrom, locationTo]).then((res) => {
        detailDeal.addressFrom = res[0].fullAddress;
        detailDeal.addressTo = res[1].fullAddress;
        detailDeal.verificationStatus = t(getVerificationStatus(detailDeal.verificationStatus));
        detailDeal.transportationPrice += ` ${profile.currency}`
        
        setOffers(createOffers(detailDeal.offers, t, profile.currency));
        setCarId(detailDeal?.carId)
        setData(createDealDetail(detailDeal, baggages, t));
      });
      const points: Array<any> = await getWayPointByPlaceIds(detailDeal);
      setIndustrialPoints(points);
      const inventoryData = await getDealInventoryById(detailDeal.id);
      const reviews = await getReviewsByDealId(detailDeal.id);
      setReviews(createReviewsFromData(reviews.ratings))

      setInventory(inventoryData);
    })();
  }, [baggages, t]);
  const changeReview = async (data) => {
    const rating = await changeRating(data);
    const reviews = await getReviewsByDealId(id);
    setReviews(createReviewsFromData(reviews))
  }
  const deleteReview = async (id) => {
    const rating = await deleteRating(id);
    const reviews = await getReviewsByDealId(id);
    setReviews(createReviewsFromData(reviews))
  }
  return (
    <React.Fragment>
      <div>
        <h1 style={{ textAlign: 'center' }}>{t('trip')}</h1>

        <div
          style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
          <div style={{ width: '40%', marginRight: '2%' }}>
            <MUIDataTable
              title={''}
              data={data.slice(0, 7)}
              columns={COLUMN(t)}
              options={OPTIONS(t)}
            />
          </div>
          <div style={{ width: '40%', marginLeft: '2%' }}>
            <MUIDataTable
              title={''}
              data={data.slice(7, data.length)}
              columns={COLUMN(t,carId)}
              options={OPTIONS(t)}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: '20px auto' }}>
        <Button variant='outlined' onClick={() => history.goBack()}>
          {t('back')}
        </Button>
      </div>
      <div style={{ width: '100%', margin: '30px' }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('orders')} (${offers.length})`} />
            <Tab value='2' label={`${t('reviews')} (${reviews.length})`} />
            <Tab value='3' label={`${t('invetory')} (${inventory.length})`} />
            <Tab value='4' label={`${t('industrialPoints')} (${industrialPoints.length})`} />
          </Tabs>
          <TabPanel value='1'>
            <MUIDataTable
              title={''}
              data={offers}
              columns={COMLUMNOFFERS(t)}
              options={OPTIONS(t)}
            />
           
          </TabPanel>
          <TabPanel value='2'>
            <ReviewComponent
              data={reviews}
              changeReview={changeReview}
              deleteReview={deleteReview}
            />
          </TabPanel>
          <TabPanel value='3'>
              <MUIDataTable
                title={''}
                data={inventory}
                columns={GET_COLUMN(t)}
                options={{...OPTIONS(t),  
                   }}
              />
            </TabPanel>
            <TabPanel value='4'>
              <MUIDataTable
                title={''}
                data={industrialPoints}
                columns={COLUMN_POINTS(t)}
                options={{...OPTIONS(t),  
                   }}
              />
            </TabPanel>
        </TabContext>
      </div>
    </React.Fragment>
  );
};

export default DealDetail;
