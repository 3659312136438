import React, { FC, useEffect } from 'react';
import i18n from "i18next";
import { Main } from './router/navigation';
import { AppProvider } from './context/AppContext';
import './i18n';

export const App: FC = () => {
  const language = localStorage.getItem('language');
  const path = window.location.pathname;
 useEffect(()=>{
  if(language){
    i18n.changeLanguage(language);
  }
  else{
    i18n.changeLanguage("ru");
  }
 },[language])
  return ( path === '/health' ?
    <>{JSON.stringify({"message": "The API is healthy"})}</> :
    <AppProvider>
      <Main />
    </AppProvider>
  );
};

export default App;
