/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import VerificationDealModel from 'models/verification';


type verificationDealResponseModel = {
  dealId: string,
  clientId: string,
  imgs: Array<string>,
  comment: string,
  isReviewed: boolean,
  verificationStatus: number,
  id: string,
  createdOn: string,
  createdOnTS: number,
  fio: string,
};

const createDealfromRowData = (responseItem: verificationDealResponseModel): any => {
  return new VerificationDealModel({
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    clientId: responseItem?.clientId,
    verificationStatus: responseItem?.verificationStatus,
    id: responseItem.id,
    dealId: responseItem.dealId,
    isReviewed: responseItem.isReviewed,
    fio: responseItem.fio,
  });
};

export const createVerificationDealFromRowData = (
  response: Array<verificationDealResponseModel>
): Array<VerificationDealModel> =>
  response.map((item: verificationDealResponseModel) => createDealfromRowData(item));

export default createVerificationDealFromRowData;
