class PopularCityModel {
  readonly createdOn: string;
  readonly imageUrl: string;
  readonly city:string;
  readonly id:number;

  constructor({
    createdOn,
    imageUrl,
    city,
    id
  }) {
    this.createdOn = createdOn;
    this.imageUrl = imageUrl;
    this.city = city;
    this.id=id
  }
}

export default PopularCityModel;
