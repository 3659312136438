import moment from 'moment';
import { returnBaggageType } from '../util';
import DealModel from '../deal';

type dealResponseModel = {
  addressFrom: any; // TODO
  addressTo: any; // TODO
  baggageType: any; // TODO
  createdOnTS: string;
  dealDescription: string;
  dealStatus: string;
  dealType: string;
  endDateTS: string;
  id: string;
  lastModified: string;
  locationFrom: any;
  locationTo: any;
  offers: Array<any>;
  shifterFullName: string;
  shifterId: string;
  shifterPhoneNumber: string;
  startDateTS: string;
  transport: string;
  transportationPrice: number;
  wayPointCollection: Array<any>;
  isActual:boolean,
  locationFromPlaceId:string,
  locationToPlaceId:string,
  dealTitle:string,
  currency: string
};

const createDealFromRowData = (item: dealResponseModel, bagges): DealModel =>
  new DealModel({
    dealTitle: item.dealTitle,
    createdOn: moment(item.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    id: item.id,
    fio: item?.shifterFullName,
    phoneNumber: item?.shifterPhoneNumber,
    startDate: moment(item.startDateTS).format('DD.MM.YYYY HH:mm').toString(), //
    endDate: moment(item.endDateTS).format('DD.MM.YYYY HH:mm').toString(),
    addressFrom: item.addressFrom?.city, // missing
    addressTo: item.addressTo?.city, // missing
    transport: item.transport,
    sum: `${item.transportationPrice} ${item.currency}`,
    baggageType: item?.baggageType.map((item: string, index: number) =>
      returnBaggageType(item, bagges, index)
    ),
    dealStatus: item?.dealStatus,
    dealType:item?.dealType,
    offerSum:item.offers,
    offerFio:item.offers,
    payment:item.offers,
    unvisable:item.id,
    phoneNumberOffer:item.offers,
    isActual:item?.isActual,
    locationFromPlaceId:item?.locationFromPlaceId,
    locationToPlaceId:item?.locationToPlaceId

  });

export const createDealListFromRowData = (
  responseDeal: Array<dealResponseModel>,
  bagges: Array<any>
): Array<DealModel> =>
  responseDeal.map((item: dealResponseModel) => createDealFromRowData(item, bagges));

export const createSortedDealListFromRowData = (
  responseDeal: Array<dealResponseModel>,
  bagges: Array<any>
): Array<DealModel> =>
  responseDeal
  .sort((a, b) => {
    const x = moment(a.createdOnTS);
    const y = moment(b.createdOnTS);
    return y.diff(x);
  })
    .map((item: dealResponseModel) => createDealFromRowData(item, bagges))
   ;
