



export const OPTIONS: any = (t: any) => {
    return {
      print: false,
      download: false,
      filter: true,
      search: false,
      sort: true,
      viewColumns: false,
      selectableRows: 'none',
      downloadOptions: {
        filename: "excel-format.csv",
        separator: ";",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      textLabels: {
        body: {
          noMatch: t('noMatch'),
          toolTip: t("sort"),
          columnHeaderTooltip: (column: any) => `${t("sort_by")} ${column.label}`,
        },
        pagination: {
          next: t("next_page"),
          previous: t("previous"),
          rowsPerPage: t("rowsPerPage"),
          displayRows: t("a_from"),
        },
        toolbar: {
          search: t("search"),
          downloadCsv: t("downloadCsv"),
          print: t("print"),
          viewColumns: t("viewColumn"),
          filterTable: t("filterTable"),
        },
        filter: {
          all: t("all"),
          title: t('filters'),
          reset: t("reset"),
        },
        viewColumns: {
          title: t('showColumns'),
          titleAria: t("show_hide_table_columns"),
        },
        selectedRows: {
          text: t("row_selected"),
          delete: t("delete"),
          deleteAria: t("delete_area"),
        },
      },
    }
  };