class VerificationUserModel {
  readonly id:number;
  readonly clientId:string;
  readonly verificationStatus:string;
  readonly userFullName:string;
  readonly isReviewed:boolean;
  readonly createdOn:string;
  constructor({
    id,
    clientId,
    verificationStatus,
    userFullName,
    isReviewed,
    createdOn,
  }) {
    this.id=id;
    this.clientId=clientId;
    this.verificationStatus=verificationStatus;
    this.userFullName=userFullName;
    this.isReviewed=isReviewed;
    this.createdOn=createdOn;
  }
}

export default VerificationUserModel;
