import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalImage from 'react-modal-image';

interface IProps {
  list: Array<string> | undefined;
}
export function ShifterImages(props: IProps): JSX.Element {
    const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      {props.list && props.list.length > 0 ? (
        props.list.map((element, index) => {
          return (
            <div key={index} style={{ marginRight: '15px' }}>
              <ModalImage
                small={element}
                large={element}
                alt={`Image`}
                className={'modalImage'}
                hideDownload={true}
                hideZoom={true}
              />
            </div>
          );
        })
      ) : (
        <h3>{t('no_data')}</h3>
      )}
    </div>
  );
}
