/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { ListItemText, MenuItem, ListItemIcon,Select } from '@material-ui/core';
import English from "../assets/images/united_kingdom.png"
import Russia from "../assets/images/russia.png"
import Turkey from "../assets/images/turkey.png"
import Armenia from "../assets/images/armenia.png"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'transparent',
        },
    },

})

export function Language(): JSX.Element {
    const language = localStorage.getItem('language');

    const { t } = useTranslation();
    const optionValues = [
        {
            title: t("russian"),
            icon: Russia,
            short_name: "ru"
        },
        {
            title: t("english"),
            icon: English,
            short_name: "en"
        },
        {
            title: t("armenian"),
            icon: Armenia,
            short_name: "hy"
        },
        {
            title: t("turkish"),
            icon: Turkey,
            short_name: "tr"
        },
    ];
    const [value, setvalue] = useState(language??"ru")
    const checkValue=()=>{
        return optionValues.find((element:any)=>element.short_name===value)
    }
    const classes = useStyles()

    return (
        <div>
            <Select
                style={{ backgroundColor: "#3f51b5",borderWidth:"5px",borderColor:"red"}}
                value={checkValue()?.short_name}
                className={classes.select}
                renderValue={() => {
                    return (<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img src={checkValue()?.icon} alt='something' height={'20'} width={"20"} style={{marginRight:"10px"}} />
                        <ListItemText
                            color={"white"}
                            primary={<p style={{ color: "white" }}>{checkValue()?.title}</p>}
                        />
                    </div>
                    );
                }}
                onChange={(event:any) => {
                    setvalue(event.target.value);
                    i18n.changeLanguage(event.target.value);
                    localStorage.setItem('language', event.target.value);
                    window.location.reload();
                }}
            >
                {optionValues.map(item => (
                    <MenuItem key={item.title} value={item.short_name}>
                        <ListItemIcon>
                            <img src={item.icon} alt='something' height={'20'} width={"20"} />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.title}
                        />
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
