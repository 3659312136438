import React from 'react';
import moment from 'moment';

export const createUserDetail = (detail: {
  ratingForShifter: any;
  ratingForSender: any;
  phoneNumber: any;
  secondName: any;
  firstName: any;
  thirdName: any;
  birthdayTS: moment.MomentInput;
  email: any;
  createdOnTS: moment.MomentInput;
  userType: any,
  confirmed_nomer: any,
  lastActiveTS: number,
  successDealsCount:number,
  successParcelsCount:number,
  lastKnownLocation:any,
  isInBlackList:boolean,
  verificationStatus: any
}, t: any) => {
  return [
    {
      name: t('first_name_user'),
      data: detail.firstName,
      isChanged: false
    },
    {
      name: t("second_name"),
      data: detail.secondName,
      isChanged: false
    },
    {
      name: t('third_name'),
      data: detail.thirdName,
      isChanged: false
    },
    {
      name: t("phone_number"),
      data: detail.phoneNumber,
      isChanged: false
    },
    {
      name: t('birth_day'),
      data: moment(detail.birthdayTS).format('DD.MM.YYYY').toString(),
      isChanged: false
    },
    {
      name: t("email"),
      data: detail.email,
      isChanged: false
    },
    {
      name: t('created_on'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("role"),
      data: t(detail.userType.toLowerCase()),
      isChanged: false
    },
    {
      name: t("confirmed_nomer"),
      data: ".",
      isChanged: false
    },
    {
      name: t("email_confirmed"),
      data: ".",
      isChanged: false
    },
    {
      name: t("last_geoData"),
      data: detail.lastKnownLocation,
      isChanged: false
    },
    {
      name: t("time_refresh_geoData"),
      data: moment(detail.lastKnownLocation.lastModifiedTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("number_of_trips"),
      data:detail.successDealsCount,
      isChanged: false
    },
    {
      name: t("number_of_parcels"),
      data:detail.successParcelsCount,
      isChanged: false
    },
    {
      name: t("last_activation"),
      data: moment(detail.lastActiveTS).format('DD.MM.YYYY HH:mm').toString(),
      isChanged: false
    },
    {
      name: t("rating_shifter"),
      data: detail.ratingForShifter,
      isChanged: false
    },
    {
      name: t("rating_sender"),
      data: detail.ratingForSender,
      isChanged: false
    },
    {
      name: t("black_list"),
      data: detail.isInBlackList,
      isChanged: false
    },
    {
      name: t("verificationStatus"),
      data: detail.verificationStatus,
      isChanged: false
    },
  ];
};
export const createFilesOfUser = (detail: {
  name: any;
  patronymic: any;
  surname: any;
  militaryNumber: any;
  verificationStatus: any;
}) => {
  return [
    {
      name: 'Имя',
      data: detail.name
    },
    {
      name: 'Отчество',
      data: detail.patronymic
    },
    {
      name: 'Фамилия',
      data: detail.surname
    },
    {
      name: 'militaryNumber',
      data: detail.militaryNumber
    },
    {
      name: 'Статус проверки',
      data: detail.verificationStatus
    }
  ];
};
export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t("sort"),
        columnHeaderTooltip: (column: any) => `${t("sort_by")} ${column.label}`,
      },
      pagination: {
        next: t("next_page"),
        previous: t("previous"),
        rowsPerPage: t("rowsPerPage"),
        displayRows: t("a_from"),
      },
      toolbar: {
        search: t("search"),
        downloadCsv: t("downloadCsv"),
        print: t("print"),
        viewColumns: t("viewColumn"),
        filterTable: t("filterTable"),
      },
      filter: {
        all: t("all"),
        title: t('filters'),
        reset: t("reset"),
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t("show_hide_table_columns"),
      },
      selectedRows: {
        text: t("row_selected"),
        delete: t("delete"),
        deleteAria: t("delete_area"),
      },
    },
  };
}

export const createReviewsFromData = (data: any) => {
  return data.map((review) => {
    return {
      description: review.description,
      rate: review.rate,
      imageUrl: review.imageUrl,
      createdOn: moment(review.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      id:review.id,
      userType:review.userType,
      fromUserId:review.fromUserId,
      toUserId:review.toUserId,
      parcelOrDealId:review.parcelId??review.dealId,
      hasParcel:review?.parcelId?true:false

    }
  }
  )
}