import moment from 'moment';
import {
  FormControl,
  FormGroup,
  FormLabel,
  Input,
  TextField
} from '@material-ui/core';
import Autocomplete from 'react-google-autocomplete';

export const GET_COLUMN = (filtersFromUrl: any, t): any => [
  {
    name: 'parcelTitle',
    label: t('title'),
    options: {
      sort: true,
      filter: true,
      filterList: filtersFromUrl.dealType ? [filtersFromUrl.dealType] : []
    }
  },
  {
    name: 'createdOn',
    label: t('date_of_creation'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`Oт: ${v[0]}`, `До: ${v[1]}`];
          } else if (v[0]) {
            return `Oт: ${v[0]}`;
          } else if (v[1]) {
            return `До: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.createdOnStart) {
          list[0] = filtersFromUrl.createdOnStart;
        }
        if (filtersFromUrl.createdOnEnd) {
          list[1] = filtersFromUrl.createdOnEnd;
        }
        return list;
      })(),
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('date_of_creation')}</FormLabel>
            <FormGroup row>
              <TextField
                id='startDate'
                label={t('start_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id='endDate'
                label={t('end_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'id',
    label: 'id',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    name: 'date',
    label: t('offer_date'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.dateOnStart) {
          list[0] = filtersFromUrl.dateOnStart;
        }
        if (filtersFromUrl.dateOnEnd) {
          list[1] = filtersFromUrl.dateOnEnd;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput }, obj2: { data: moment.MomentInput }) => {
          const val1 = moment(obj1.data, 'DD.MM.YYYY HH:mm');
          const val2 = moment(obj2.data, 'DD.MM.YYYY HH:mm');
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, 'DD.MM.YYYY HH:mm');
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (
          filterList: { [x: string]: any },
          onChange: (arg0: any, arg1: any, arg2: any) => void,
          index: string | number,
          column: any
        ): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('offer_date')}</FormLabel>
            <FormGroup row>
              <TextField
                id='startDate'
                label={t('start_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id='endDate'
                label={t('end_date')}
                type='date'
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'parcelSum',
    label: t('sum'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      filterList: (() => {
        const list: any = [];
        if (filtersFromUrl.parcelSumMin) {
          list[0] = filtersFromUrl.parcelSumMin;
        }
        if (filtersFromUrl.parcelSumMax) {
          list[1] = filtersFromUrl.parcelSumMax;
        }
        return list;
      })(),
      customFilterListOptions: {
        render: (v) => {
          if (v[0] && v[1]) {
            return [`${t('from')}: ${v[0]}`, `${t('to')}: ${v[1]}`];
          } else if (v[0]) {
            return `${t('from')}: ${v[0]}`;
          } else if (v[1]) {
            return `${t('to')}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        }
      },
      filterOptions: {
        names: [],
        logic(age, filters) {
          if (filters[0] && filters[1]) {
            return age < filters[0] || age > filters[1];
          } else if (filters[0]) {
            return age < filters[0];
          } else if (filters[1]) {
            return age > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('sum')}</FormLabel>
            <FormGroup row>
              <TextField
                label={t('from_what')}
                type={'number'}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
              <TextField
                label={t('to_what')}
                type={'number'}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'addressFrom',
    label: t('address_from'),
    options: {
      filterList: filtersFromUrl.addressFrom ? [filtersFromUrl.addressFrom] : [],
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return [`${v[0].name}`];
          }
          return false;
        }
      },
      filterOptions: {
        logic(actionType, filters, tableData) {
          if (filters.length > 0) {
            return tableData[11] !== filters[0].place_id;
          }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          return (
            <FormControl>
              <FormLabel style={{ fontSize: 15 }}>{t('address_from')}</FormLabel>
              <Input
                fullWidth
                color='secondary'
                style={{ width: '100%' }}
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <Autocomplete
                    apiKey={'AIzaSyCL9JBbWUVp27zGmZOUR6cErzH43tXOhGQ'}
                    {...props}
                    defaultValue={filterList[index].length > 0 ? filterList[index][0].name : ''}
                    onPlaceSelected={(selected) => {
                      filterList[index].push(selected);
                      onChange(filterList[index], index, column);
                    }}
                    options={{ fields: ['place_id', 'name'] }}
                  />
                )}
              />
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'addressTo',
    label: t('address_to'),
    options: {
      filterList: filtersFromUrl.addressTo ? [filtersFromUrl.addressTo] : [],
      filterType: 'custom' as any,
      customFilterListOptions: {
        render: (v) => {
          if (v) {
            return [`${v[0].name}`];
          }
          return false;
        }
      },
      filterOptions: {
        logic(actionType, filters, tableData) {
          if (filters.length > 0) {
            return tableData[12] !== filters[0].place_id;
          }
          return false;
        },
        display: (filterList, onChange, index, column, data) => {
          return (
            <FormControl>
              <FormLabel style={{ fontSize: 15 }}>{t('address_to')}</FormLabel>
              <Input
                fullWidth
                color='secondary'
                style={{ width: '100%' }}
                inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
                  <Autocomplete
                    apiKey={'AIzaSyCL9JBbWUVp27zGmZOUR6cErzH43tXOhGQ'}
                    {...props}
                    defaultValue={filterList[index].length > 0 ? filterList[index][0].name : ''}
                    onPlaceSelected={(selected) => {
                      filterList[index].push(selected);
                      onChange(filterList[index], index, column);
                    }}
                    options={{ fields: ['place_id', 'name'] }}
                  />
                )}
              />
            </FormControl>
          );
        }
      }
    }
  },
  {
    name: 'fio',
    label: t('fio_user'),
    options: {
      filter: true,
      sort: true,
      filterType: 'textField' as any,
      filterList: filtersFromUrl.fio ? [filtersFromUrl.fio] : []
    }
  },
  {
    name: 'deliveryStatus',
    label: t('new_statuses'),
    filterList: filtersFromUrl.deliveryStatus ? [filtersFromUrl.deliveryStatus] : [],
  },
  {
    name: 'locationFromPlaceId',
    label: 'locationFromPlaceId',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    name: 'locationToPlaceId',
    label: 'locationToPlaceId',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  }
];
export const OPTIONS: any = (t: any) => {
  return {
    print: false,
    download: true,
    filter: false,
    search: false,
    sort: true,
    viewColumns: false,
    selectableRows: 'none',
    onDownload: (buildHead, buildBody, columns, data) => {
      return '\uFEFF' + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: 'excel-format.csv',
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true
      }
    },
    setRowProps: (row) => {
    },
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};
