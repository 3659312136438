/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { TextField, Tabs, Tab, Select, MenuItem, ListItemText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createReviewsFromData, createUserDetail, OPTIONS } from './utils';
import UserParcelList from '../UserComponent/UserParcelList/UserParcelList';
import UserDealList from '../UserComponent/UserDealList/UserDealList';
import { BlackList, CarsClient, ParcelClient, TripClient, UserClient, RatingApi, PlaceDetails, ProfileClient } from '../../services/api';
import moment from 'moment';
import { Rating, TabContext, TabPanel } from '@material-ui/lab';
import { createSortedDealListFromRowData } from 'models/modelFactory/deal';
import { AppContextType, useApp } from 'context/AppContext';
import { createParcelsListFromRowData } from 'models/modelFactory/parcel';
import ReviewComponent from './components/reviews/Reviews';
import Hundred from '../../assets/images/hundred.svg'
import Ten from '../../assets/images/ten.svg'
import Fifty from '../../assets/images/fifty.svg'
import Thirty from '../../assets/images/thirty.svg'
import CarsComponent from 'components/CarsComponent/CarsComponent';
import { ShifterImages } from 'components/ParcelDetail/ShifterImages';
import DialogComponent from './DialogComponent';
import DefaultNotice from 'components/NoticeComponent';
import { getVerificationStatus } from 'models/util';

const UserDetail = function (): JSX.Element {
  const { t } = useTranslation();

  const title = t('user_information');
  const { id } = useParams();
  const { changeRating, deleteRating, getPaginatedRatings } = RatingApi();
  const { getPlaceDetails } = PlaceDetails();
  const { getProfileDefaults } = ProfileClient();
  const { getUserById, changeUserDetail, findFilesOfUserById, getUserAvatarById, getRatingByUser, changeUserRating } = UserClient();
  const [dealList, setDealList] = useState<any>([]);
  const [parcelList, setParcelList] = useState<any>([]);
  const [data, setData] = useState<any[]>([]);
  const [filles, setFilles] = useState<any[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [secondName, setSecondName] = useState<string>('');
  const [firstName, setFirstname] = useState<string>('');
  const [successDealsCount, setSuccessDealsCount] = useState<string>('');
  const [successParcelsCount, setSuccessParcelsCount] = useState<string>('');
  const [thirdName, setThirdName] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [userType, setUserType] = useState<any>('');
  const [open, setOpen] = useState(false);

  const [totalDealPages, setTotalDealPages] = useState<number>(1);
  const [dealTotal, setDealTotal] = useState<number>(0);
  const [dealPage, setDealPage] = useState<number>(0);
  const [dealPageSize, setDealPageSize] = useState<number>(10);

  const [totalParcelPages, setTotalParcelPages] = useState<number>(1);
  const [parcelTotal, setParcelTotal] = useState<number>(0);
  const [parcelPage, setParcelPage] = useState<number>(0);
  const [parcelPageSize, setParcelPageSize] = useState<number>(10);

  const [totalReviewPages, setTotalReviewPages] = useState<number>(1);
  const [reviewTotal, setReviewTotal] = useState<number>(0);
  const [reviewPage, setReviewPage] = useState<number>(0);
  const [reviewPageSize, setReviewPageSize] = useState<number>(10);

  const [totalCarPages, setTotalCarPages] = useState<number>(1);
  const [carTotal, setCarTotal] = useState<number>(0);
  const [carPage, setCarPage] = useState<number>(0);
  const [carPageSize, setCarPageSize] = useState<number>(10);

  const [url, setUrl] = useState<string>(
    'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'
  );
  const [value, setValue] = React.useState('1');
  const { getPaginatedTrips } = TripClient();
  const { addUserToBlackList ,deleteBlackListById} = BlackList();
  const { baggages } = useApp() as AppContextType;
  const { getPaginatedParcels } = ParcelClient();
  const optionValues = ['Shifter', 'Sender', 'ShifterSender'];
  const [change, setChange] = useState(false);
  const [reviews, setReviews] = useState([])
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };
  const [isVisable, setIsVisable] = useState(false);
  const [resason, setReason] = useState("")
  const { getPaginatedCars } = CarsClient();

  enum ratingTypes {
    shifter = 'ratingForShifter',
    sender = 'ratingForSender'
  };

  const [cars, setCars] = useState<any>([])
  const renderCountImage = (count) => {
    switch (true) {
      case count < 10:
        return <img src={Thirty} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
      case count >= 10 && count < 50:
        return <img src={Ten} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
      case count >= 50 && count < 100:
        return <img src={Fifty} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
      case count >= 100:
        return <img src={Hundred} alt='something' height={'50'} width={"50"} style={{ marginLeft: 10 }} />
      default:
        <></>
    }
  }
  const setValueByRowIndex = (index: number, valueTextField: string) => {
    switch (index) {
      case 3:
        setPhoneNumber(valueTextField);
        break;
      case 1:
        setSecondName(valueTextField);
        break;
      case 0:
        setFirstname(valueTextField);
        break;
      case 2:
        setThirdName(valueTextField);
        break;
      case 4:
        setBirthday(valueTextField);
        break;
      case 5:
        setEmail(valueTextField);
        break;

      default:
        break;
    }
  };
  const setValueByRowIndexForTwo = (index: number, valueTextField: string) => {
    switch (index) {
      case 3:
        setSuccessDealsCount(valueTextField);
        break;
      case 4:
        setSuccessParcelsCount(valueTextField);
        break;

      default:
        break;
    }
  };
  const COLUMN_TWO = [
    {
      name: 'name',
      label: '',
      options: {
        customHeadRender: ({ index }) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        )
      }
    },
    {
      name: 'data',
      label: '',
      options: {
        customHeadRender: ({ index }) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        ),
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            {tableMeta.rowIndex !== 1 && tableMeta.rowIndex !== 2 && tableMeta.rowIndex !== 8 && tableMeta.rowIndex !== 7 &&
              tableMeta.rowIndex !== 6 && tableMeta.rowIndex !== 9 &&
              tableMeta.rowIndex !== 0 && tableMeta.rowIndex !== 5 &&
              (tableMeta.tableData[tableMeta.rowIndex][2] ||
              tableMeta.tableData[tableMeta.rowIndex].isChanged) ? (
              (
                <TextField
                  value={value}
                  type={
                    'number'
                  }
                  onChange={(event: any) => {
                    const valueTextField: any = event.nativeEvent.target.value;
                    const index: number = tableMeta.rowIndex;

                    setValueByRowIndexForTwo(index, valueTextField);
                    updateValue(event.nativeEvent.target.value);
                  }}
                  InputProps={{ inputProps: { max: '2022-01-01' } }}
                />
              )
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                {(tableMeta.rowIndex === 6) &&
                  <Rating
                    name={ratingTypes.shifter}
                    value={value}
                    precision={1}
                    onChange={async (evt, newValue) => {
                      await changeUserRating(id, {[ratingTypes.shifter]: newValue ?? 0});
                      updateValue(newValue);
                    }
                  } />
                }
                {(tableMeta.rowIndex === 7) &&
                  <Rating
                    name={ratingTypes.sender}
                    value={value}
                    precision={1}
                    onChange={async (evt, newValue) => {
                      await changeUserRating(id, {[ratingTypes.sender]: newValue ?? 0});
                      updateValue(newValue);
                    }
                  } />
                }
                {tableMeta.rowIndex !== 1 ?
                  <>
                    <p style={{ marginLeft: "10px" }}>{tableMeta.rowIndex === 8?(value?t('yes'):t('no')):tableMeta.rowIndex === 9?t(getVerificationStatus(value)):value}</p>
                    {(tableMeta.rowIndex === 3 || tableMeta.rowIndex === 4) && renderCountImage(value)}
                  </>
                  :
                  <a href={`https://www.google.com/maps/search/?api=1&query=${value.lat},${value.long}`} target="_blank" rel="noreferrer">
                    {t("open_in_the_map")}
                  </a>
                }
              </div>
            )}
          </>
        )
      }
    },
    {
      name: 'isChanged',
      label: 'id',
      options: {
        display: false,
        viewColumns: false
      }
    }
  ];
  const COLUMN = [
    {
      name: 'name',
      label: '',
      options: {
        customHeadRender: ({ index }) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        )
      }
    },
    {
      name: 'data',
      label: '',
      options: {
        customHeadRender: ({ index }) => (
          <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
        ),
        customBodyRender: (
          value: any,
          tableMeta: any,
          updateValue: (arg0: any) => void
        ): JSX.Element => (
          <>
            {tableMeta.rowIndex !== 6 &&
              (tableMeta.tableData[tableMeta.rowIndex][2] ||
                tableMeta.tableData[tableMeta.rowIndex].isChanged) ? (
              tableMeta.rowIndex === 7 ? (
                <Select
                  value={value}
                  onChange={(event) => {
                    setUserType(event.target.value);
                    updateValue(event.target.value);
                  }}>
                  {optionValues.map((item, index) => (
                    <MenuItem key={item} value={item}>
                      <ListItemText primary={item} />
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <TextField
                  value={value}
                  type={
                    tableMeta.rowIndex === 4 ? 'date' : tableMeta.rowIndex === 5 ? 'email' : 'text'
                  }
                  onChange={(event: any) => {
                    const valueTextField: any = event.nativeEvent.target.value;
                    const index: number = tableMeta.rowIndex;

                    setValueByRowIndex(index, valueTextField);
                    updateValue(event.nativeEvent.target.value);
                  }}
                  InputProps={{ inputProps: { max: '2022-01-01' } }}
                />
              )
            ) : (
              <p>{value}</p>
            )}
          </>
        )
      }
    },
    {
      name: 'isChanged',
      label: 'id',
      options: {
        display: false,
        viewColumns: false
      }
    }
  ];

  useEffect(() => {
    (async () => {
      const tripData = await getPaginatedTrips(dealPage + 1, dealPageSize, id);
      const reviewData = await getPaginatedRatings(reviewPage + 1, reviewPageSize, id);
      const profile = await getProfileDefaults();
      const carData = await getPaginatedCars(carPage + 1, carPageSize, id);
      setCars(carData.cars);
      const parsedDealData = tripData
        ? createSortedDealListFromRowData(tripData.deals.map((deal: any) => {return {...deal, currency: profile.currency}}), baggages)
        : [];
      setDealList(await getLocalizedData(parsedDealData));
      const parcelData = await getPaginatedParcels(parcelPage + 1, parcelPageSize, id);
      const parsedParcelData = parcelData
        ? createParcelsListFromRowData(parcelData.parcels.map((parcel: any) => {return {...parcel, currency: profile.currency}}), baggages, t)
        : [];
      setParcelList(await getLocalizedData(parsedParcelData));
      const filesOfUser = await findFilesOfUserById(id);
      const getUrl = await getUserAvatarById(id);
      getUrl?.url && setUrl(getUrl.url);
      const detailUser = await getUserById(id);
      setUserType(detailUser.userType);
      setPhoneNumber(detailUser.phoneNumber);
      setSuccessDealsCount(detailUser.successDealsCount);
      setSuccessParcelsCount(detailUser.successParcelsCount);
      setSecondName(detailUser.secondName);
      setFirstname(detailUser.firstName);
      setThirdName(detailUser.thirdName);
      setBirthday(detailUser.birthdayTS);
      setEmail(detailUser.email);
      setData(createUserDetail(detailUser, t));
      setFilles(filesOfUser);
      setReviews(createReviewsFromData(reviewData.ratings));
      setDealTotal(tripData.pagination.total);
      setTotalDealPages(tripData.pagination.totalPages);
      setParcelTotal(parcelData.pagination.total);
      setTotalParcelPages(parcelData.pagination.totalPages);
      setReviewTotal(reviewData.pagination.total);
      setTotalReviewPages(reviewData.pagination.totalPages);
      setCarTotal(carData.pagination.total);
      setTotalCarPages(carData.pagination.totalPages);
    })();
  }, [id, url, baggages, t, dealPage, dealPageSize, parcelPage, parcelPageSize, reviewPage, reviewPageSize, carPage, carPageSize]);
   
  const unlock=async()=>{
    const unlock = await deleteBlackListById(id);
    window.location.reload()

  }
  const changeData = (value: boolean) => {
    const array = [...data];
    for (let index = 0; index < array.length; index++) {
      array[index].isChanged = value;
    }
    setData(array);
  };
  const getLocalizedData = async (data) => {
    const locationFromData = await Promise.all(data.map(item => getPlaceDetails(item.locationFromPlaceId)));
    const locationToData = await Promise.all(data.map(item => getPlaceDetails(item.locationToPlaceId)));
    return data.map(elem => {
      const locationFrom = locationFromData.find(item => item.placeId === elem.locationFromPlaceId)
      const locationTo = locationToData.find(item => item.placeId === elem.locationToPlaceId)
      return {
        ...elem,
        addressFrom: locationFrom.fullAddress,
        addressTo: locationTo.fullAddress,
      }
    });
  }
  const saveChangeUserDetail = async () => {
    const data = {
      id: id,
      phoneNumber: phoneNumber,
      secondName: secondName,
      firstName: firstName,
      thirdName: thirdName,
      birthdayTS: moment(birthday).unix() * 1000,
      email: email,
      userType: userType,
      successDealsCount: parseInt(successDealsCount),
      successParcelsCount: parseInt(successParcelsCount)
    };
    const detailUser = await changeUserDetail(data);
    //changeData(false)
    setData(createUserDetail(detailUser, t));
  };
  const addToBlackList = async () => {
    const data = {
      reason: resason,
      p2PUserId: id,
      deviceID: "Web"
    }
    const add = await addUserToBlackList(data);
     setOpen(true);setIsVisable(false)
  }
  const dataTable = useMemo(
    () => (
      <MUIDataTable
        title={''}
        data={data.slice(0, 9)}
        columns={COLUMN}
        options={{
          ...OPTIONS(t)
        }}
      />
    ),
    [data, url]
  );
  const dataTableTwo = useMemo(
    () => (
      <MUIDataTable
        title={''}
        data={data.slice(9, data.length)}
        columns={COLUMN_TWO}
        options={{
          ...OPTIONS(t)
        }}
      />
    ),
    [data, url]
  );
  const changeReview = async (data) => {
    const rating = await changeRating(data);
    const reviews = await getRatingByUser(id);
    setReviews(createReviewsFromData(reviews))
  }
  const deleteReview = async (id) => {
    const rating = await deleteRating(id);
    const reviews = await getRatingByUser(id);
    setReviews(createReviewsFromData(reviews))
  }
  return (
    <React.Fragment>
      <div style={{}}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px'
          }}>
          <img
            src={url}
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10px' }}
            alt={'avatar'}
          />
          <h1>{title}</h1>
        </div>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ width: '40%', marginRight: '2%' }}>{dataTable}</div>
          <div style={{ width: '40%', marginLeft: '2%' }}>{dataTableTwo}</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => { setChange(!change); changeData(!change) }}>
              {change ? t('cancel') : t('change')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={saveChangeUserDetail}>
              {t('save')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={() => {data.length>0&&!data[17].data ?setIsVisable(true):unlock() }}>
              {data.length>0&& data[17].data?t('unlock'): t('add_to_black_list')}
            </Button>
          </div>
        </div>

        <DialogComponent
          open={isVisable}
          handleClose={() => {
            setIsVisable(false);
          }}
          reason={resason}
          title={t('reason')}
          setReason={setReason}
          add={addToBlackList}
        />
      </div>
      <div style={{ width: '100%', margin: '30px' }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('deals')} (${dealTotal})`} />
            <Tab value='2' label={`${t('parcels')} (${parcelTotal})`} />
            <Tab value='3' label={`${t('reviews')} (${reviewTotal})`} />
            <Tab value='4' label={`${t('cars')} (${carTotal})`} />
            <Tab value='5' label={`${t('documents')} (${filles.length})`} />
          </Tabs>
          <TabPanel value='1'>
            <UserDealList
              dealList={dealList}
              paginationData={
                {
                  page: dealPage,
                  setPage: setDealPage,
                  pageSize: dealPageSize,
                  setPageSize: setDealPageSize,
                  total: dealTotal,
                  totalPages: totalDealPages
                }
              }
            />
          </TabPanel>
          <TabPanel value='2'>
            <UserParcelList
            data={parcelList}
            paginationData={
              {
                page: parcelPage,
                setPage: setParcelPage,
                pageSize: parcelPageSize,
                setPageSize: setParcelPageSize,
                total: parcelTotal,
                totalPages: totalParcelPages
              }
            }
          />
          </TabPanel>
          <TabPanel value='3'>
            <ReviewComponent
              data={reviews}
              changeReview={changeReview}
              deleteReview={deleteReview}
              paginationData={
                {
                  page: reviewPage,
                  setPage: setReviewPage,
                  pageSize: reviewPageSize,
                  setPageSize: setReviewPageSize,
                  total: reviewTotal,
                  totalPages: totalReviewPages
                }
              }
            />
          </TabPanel>
          <TabPanel value='4'>
            <CarsComponent
              data={cars}
              paginationData={
                {
                  page: carPage,
                  setPage: setCarPage,
                  pageSize: carPageSize,
                  setPageSize: setCarPageSize,
                  total: carTotal,
                  totalPages: totalCarPages
                }
              }
            />
          </TabPanel>
          <TabPanel value='5'>
            <ShifterImages
              list={filles}
            />
          </TabPanel>
        </TabContext>
        {open && <DefaultNotice />}

      </div>
    </React.Fragment>
  );
};

export default UserDetail;
