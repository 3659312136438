/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { COLUMN, OPTIONS, createBlackListDetail, } from './utils';
import { BlackList } from '../../services/api';

const BlackListDetail = function (): JSX.Element {
  const { t } = useTranslation();
  const title=t("user_information")
  const { id } = useParams();
  const { getBlackListById } = BlackList();
  const [data, setData] = useState<any[]>([]);
  const [url, setUrl] = useState<string>(
    'https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg'
  );

  useEffect(() => {
    (async () => {

      const blackListById = await getBlackListById(id);

      const deatil:any = createBlackListDetail(blackListById, t);
      setData(deatil);
      blackListById?.avatarUrl&& setUrl(blackListById?.avatarUrl)
    })();
  }, [t]);



  return (
    <React.Fragment>
             <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px'
          }}>
          <img
            src={url}
            style={{ width: '100px', height: '100px', borderRadius: '50%', marginRight: '10px' }}
            alt={'avatar'}
          />
          <h1>{title}</h1>
        </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data}
            columns={COLUMN(t)}
            options={OPTIONS(t)}
          />
        </div>

      </div>

 
    </React.Fragment>
  );
};

export default BlackListDetail;
