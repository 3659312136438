class CarModel {
  readonly createdOn: string;
  readonly brand: string;
  readonly model:string;
  readonly id:number;
  readonly clientId:string;
  readonly verificationStatus:string;
  readonly image:string;
  readonly fio: string;
  constructor({
    createdOn,
    brand,
    model,
    id,
    clientId,
    verificationStatus,
    image,
    fio,
  }) {
    this.createdOn = createdOn;
    this.brand = brand;
    this.model = model;
    this.id=id;
    this.clientId=clientId;
    this.verificationStatus=verificationStatus;
    this.image=image;
    this.fio=fio;
  }
}

export default CarModel;
