class DealModel {
  readonly dealTitle: string;
  readonly createdOn: string;
  readonly id: string;
  readonly fio: string;
  readonly phoneNumber: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly addressFrom: string;
  readonly addressTo: string;
  readonly transport: string;
  readonly sum: string;
  readonly baggageType: Array<string>;
  readonly dealStatus: string;
  readonly dealType: string;
  readonly offerSum: string;
  readonly offerFio:string;
  readonly payment:string;
  readonly unvisable:string;
  readonly phoneNumberOffer:string;
  readonly isActual:boolean;
  readonly locationFromPlaceId:string;
  readonly locationToPlaceId:string;

  constructor({
    dealTitle,
    createdOn,
    id,
    fio,
    phoneNumber,
    startDate,
    endDate,
    addressFrom,
    addressTo,
    transport,
    sum,
    baggageType,
    dealStatus,
    dealType,
    offerSum,
    offerFio,
    payment,
    unvisable,
    phoneNumberOffer,
    isActual,
    locationFromPlaceId,
    locationToPlaceId
  }) {
    this.dealTitle = dealTitle;
    this.createdOn = createdOn;
    this.id = id;
    this.fio = fio;
    this.phoneNumber = phoneNumber;
    this.startDate = startDate;
    this.endDate = endDate;
    this.addressFrom = addressFrom;
    this.addressTo = addressTo;
    this.transport = transport;
    this.sum = sum;
    this.baggageType = baggageType;
    this.dealStatus = dealStatus;
    this.dealType=dealType
    this.offerSum=offerSum
    this.offerFio=offerFio
    this.payment=payment
    this.unvisable=unvisable
    this.phoneNumberOffer=phoneNumberOffer
    this.isActual=isActual
    this.locationFromPlaceId=locationFromPlaceId
    this.locationToPlaceId=locationToPlaceId
  }
}

export default DealModel
