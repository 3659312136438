/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button, Tab, Tabs } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { COLUMN, OPTIONS, createVerificationRequestCar, } from './utils';
import { Verification } from '../../services/api';
import { ShifterImages } from 'components/ParcelDetail/ShifterImages';
import DialogComponent from 'components/UserDetail/DialogComponent';
import { getVerificationStatus } from 'models/util';

const VerificationCarRequestDetail = function (): JSX.Element {

  enum vfStatus {
    notVerified = 0,
    verification_pending = 1,
    verified = 2,
    verificationFailure = 3
  }

  const { t } = useTranslation();

  const title = t('detail_verification');
  const { id } = useParams();
  const { getVerificationCarById, changeVerificationCarById } = Verification();
  const [data, setData] = useState<any[]>([]);
  const [value, setValue] = React.useState('1');
  const [images, setImages] = useState([]);
  const [isVisable,setIsVisable]=useState(false);
  const [comment,setComment]=useState('');
  const [pasportPhotos,setPasportPhotos]=useState([])
  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {

      const carById = await getVerificationCarById(id);
      carById.verificationStatus = t(getVerificationStatus(carById.verificationStatus));
      const detail = createVerificationRequestCar(carById, t);
      setPasportPhotos(carById.carPassportPhotos);
      setImages(carById.imgs);
      setData(detail);

    })();
  }, [t,id]);

  const changeConfirm = async (value: boolean, vfStatusCode: number) => {
    const data = {
      id: id,
      isReviewed: value,
      comment:comment,
      verificationStatus: vfStatusCode
    }
  
    const deal = await changeVerificationCarById(data);
    setComment('');
    setIsVisable(false);
    window.location.reload();
  }

return (
  <React.Fragment>
    <h1 style={{ textAlign: 'center' }}>{title}</h1>
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
      <div style={{ width: '40%', marginRight: '2%' }}>
        <MUIDataTable
          title={''}
          data={data.slice(0, 9)}
          columns={COLUMN(t)}
          options={OPTIONS(t)}
        />
      </div>

    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <div style={{ margin: '5px' }}>
        <Button variant='outlined' style={{backgroundColor:'#008000'}} onClick={() => { changeConfirm(true, vfStatus.verified)}} >
          {t('confirm')}
        </Button>
      </div>
      <div style={{ margin: '5px' }}>
        <Button variant='outlined' style={{backgroundColor:'#c70000'}} onClick={() => {setIsVisable(true) }}>
          {t('no_confirm')}
        </Button>
      </div>


    </div>
    <DialogComponent
          open={isVisable}
          handleClose={() => {
            setIsVisable(false);
          }}
          reason={comment}
          title={t('reason')}
          setReason={setComment}
          add={()=>changeConfirm(false, vfStatus.verificationFailure) }
        />
    <div style={{ width: '100%', margin: '30px' }}>
      <TabContext value={value}>
        <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
          <Tab value='1' label={`${t('photo_inspection')}`} />
          <Tab value='2' label={`${t('car_photo')}`} />
        </Tabs>

        <TabPanel value='1'>
          <ShifterImages list={pasportPhotos} />
        </TabPanel>
        <TabPanel value='2'>
          <ShifterImages list={images} />
        </TabPanel>
      </TabContext>
    </div>
  </React.Fragment>
);
};

export default VerificationCarRequestDetail;
