class BlackListModel {
  readonly createdOn: string;
  readonly firstName: string;
  readonly secondName:string;
  readonly email:string;
  readonly phoneNumber:string;
  readonly id:string;
  constructor({
    createdOn,
    firstName,
    secondName,
    email,
    phoneNumber,
    id
  }) {
    this.createdOn = createdOn;
    this.firstName = firstName;
    this.secondName = secondName;
    this.email=email;
    this.phoneNumber=phoneNumber;
    this.id=id
  }
}

export default BlackListModel;
