/* eslint-disable import/no-extraneous-dependencies */
import moment from 'moment';
import VerificationUserModel from 'models/verificationUser';


type verificationUserResponseModel = {
  userId: string,
  selfieImgUrl: string,
  documentsUrl: string[],
  comment: string,
  isReviewed: boolean,
  verificationStatus: string,
  id: string,
  createdOn: string,
  createdOnTS: number,
  userFullName:string
};

const createUserfromRowData = (responseItem: verificationUserResponseModel): any => {
  return new VerificationUserModel({
    clientId: responseItem?.userId,
    verificationStatus: responseItem?.verificationStatus,
    id: responseItem.id,
    userFullName: responseItem.userFullName,
    isReviewed: responseItem.isReviewed,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
  });
};

const createVerificationUserFromRowData = (
  response: Array<verificationUserResponseModel>
): Array<VerificationUserModel> =>
  response.map((item: verificationUserResponseModel) => createUserfromRowData(item));

export default createVerificationUserFromRowData;
