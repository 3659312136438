import moment from 'moment';

export const COLUMN = (t: any) => [
  {
    name: 'name',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      )
    }
  },
  {
    name: 'data',
    label: '',
    options: {
      customHeadRender: ({ index }) => (
        <th key={index} style={{ borderBottom: '0px solid rgba(224, 224, 224, 1)' }} />
      ),
     
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ cursor: 'pointer' }}>
              <p
                onClick={() => {
                  tableMeta.rowIndex ===0&& window.open(`/user/details/${value}`, '_blank');
                }}>
                {value}
              </p>
            </div>
          );
      }
    }
  }
];

export const createBlackListDetail = (
  detail: any,
  t: any
): { name: string; data: any }[] => {
  return [
    {
      name: t('ID'),
      data: detail.p2PUserId
    },
    {
      name: t('date_of_creation'),
      data: moment(detail.createdOnTS).format('DD.MM.YYYY HH:mm').toString()
    },
    {
      name: t('first_name'),
      data: detail.firstName
    },
    {
      name: t('second_name'),
      data: detail.secondName
    },
    {
      name: t('email'),
      data: detail.email
    },
    {
      name: t('phone_number'),
      data: detail.phoneNumber
    },
    {
      name: t('reason'),
      data: detail.reason
    },
  ];
};

export const OPTIONS: any = (t: any) => {
  return {
    filter: false,
    responsive: 'vertical',
    draggableColumns: {
      enabled: true
    },
    print: false,
    download: false,
    search: false,
    sort: false,
    viewColumns: false,
    selectableRows: 'none',
    pagination: false,
    textLabels: {
      body: {
        noMatch: t('noMatch'),
        toolTip: t('sort'),
        columnHeaderTooltip: (column: any) => `${t('sort_by')} ${column.label}`
      },
      pagination: {
        next: t('next_page'),
        previous: t('previous'),
        rowsPerPage: t('rowsPerPage'),
        displayRows: t('a_from')
      },
      toolbar: {
        search: t('search'),
        downloadCsv: t('downloadCsv'),
        print: t('print'),
        viewColumns: t('viewColumn'),
        filterTable: t('filterTable')
      },
      filter: {
        all: t('all'),
        title: t('filters'),
        reset: t('reset')
      },
      viewColumns: {
        title: t('showColumns'),
        titleAria: t('show_hide_table_columns')
      },
      selectedRows: {
        text: t('row_selected'),
        delete: t('delete'),
        deleteAria: t('delete_area')
      }
    }
  };
};

