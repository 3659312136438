/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import PopularCityModel from 'models/popularCity';

type popularCityResponseModel = {
  createdOnTS: any;
  imageUrl: string;
  placeId: string;
  id: number;
};

const createPopularCityFromRowData = (responseItem: popularCityResponseModel, index: number, cities: Array<popularCityResponseModel>): PopularCityModel => {
    return new PopularCityModel({
      createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
      imageUrl: responseItem?.imageUrl,
      city: cities[index].placeId,
      id: responseItem.id
  });
  
  
};

export const createPopularCitiesFromRowData = (
  response: Array<popularCityResponseModel>
): Array<PopularCityModel> =>
  response.map((item: popularCityResponseModel, index: number) => createPopularCityFromRowData(item, index, response));

export default createPopularCitiesFromRowData;
