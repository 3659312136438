import moment from 'moment';
import { FormGroup, FormLabel, TextField } from '@material-ui/core';

export const COLUMN =(t:any)=> [
  {
    name: 'createdOn',
    label: t('date_of_creation'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      //sortDirection: 'desc',
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
          const val1 = moment(obj1.data,"DD.MM.YYYY HH:mm");
          const val2 = moment(obj2.data,"DD.MM.YYYY HH:mm");
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, "DD.MM.YYYY HH:mm");
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
          <div>
            <FormLabel>{t('date_of_creation')}</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label={t("start_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id="endDate"
                label={t("end_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'fio',
    label: t('fio_carrier'),
    options: {
      filter: true,
      sort: true,
      filterType: 'textField' as any
    }
  },
  {
    name: 'phoneNumber',
    label: t("number_carrier")
  },
  {
    name: 'id',
    label: 'id',
    options: {
      display: false,
      viewColumns: false,
      filter: false
    }
  },
  {
    name: 'startDate',
    label: t('travel_start_date'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      //sortDirection: 'desc',
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
          const val1 = moment(obj1.data,"DD.MM.YYYY HH:mm");
          const val2 = moment(obj2.data,"DD.MM.YYYY HH:mm");
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, "DD.MM.YYYY HH:mm");
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('travel_start_date')}</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label={t("start_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id="endDate"
                label={t("end_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'endDate',
    label: t('travel_end_date'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      //sortDirection: 'desc',
      sortCompare: (order: string) => {
        return (obj1: { data: moment.MomentInput; }, obj2: { data: moment.MomentInput; }) => {
          const val1 = moment(obj1.data,"DD.MM.YYYY HH:mm");
          const val2 = moment(obj2.data,"DD.MM.YYYY HH:mm");
          return val1.diff(val2) * (order === 'asc' ? -1 : 1);
        };
      },
      filterOptions: {
        names: [],
        logic(date: moment.MomentInput, filters: any[]): boolean {
          const check: any = moment(date, "DD.MM.YYYY HH:mm");
          const from: any = moment(filters[0], 'YYYY-MM-DD');
          const to: any = moment(filters[1], 'YYYY-MM-DD');
          if (
            filters[0] &&
            filters[1] &&
            check.diff(to, 'days') > 0 &&
            check.diff(from, 'days') < 0
          ) {
            return true;
          } else if (filters[1] && check.diff(to, 'days') > 0) {
            return true;
          } else if (filters[0] && check.diff(from, 'days') < 0) {
            return true;
          }
          return false;
        },
        display: (filterList: { [x: string]: any; }, onChange: (arg0: any, arg1: any, arg2: any) => void, index: string | number, column: any): JSX.Element => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t("travel_end_date")}</FormLabel>
            <FormGroup row>
              <TextField
                id="startDate"
                label={t("start_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
              <TextField
                id="endDate"
                label={t("end_date")}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={filterList[index][1] || ''}
                onChange={(event) => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', marginTop: '3%' }}
              />
            </FormGroup>
          </div>
        )
      }
    }
  },
  {
    name: 'addressFrom',
    label:t("address_from")
  },
  {
    name: 'addressTo',
    label:t("address_to")
  },
  {
    name: 'transport',
    label: t('type_transport')
  },
  {
    name: 'sum',
    label: t('sum'),
    options: {
      filter: true,
      sort: true,
      filterType: 'custom' as any,
      // filterList: [25, 50],
      customFilterListOptions: {
        render: v => {
          if (v[0] && v[1]) {
            return [`${t("from")}: ${v[0]}`, `${t("to")}: ${v[1]}`];
          } else if (v[0]) {
            return `${t("from")}: ${v[0]}`;
          } else if (v[1]) {
            return `${t("to")}: ${v[1]}`;
          }
          return false;
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            filterList[index] = [];
          }

          return filterList;
        },
      },
      filterOptions: {
        names: [],
        logic(age, filters) {
          if (filters[0] && filters[1]) {
            return age < filters[0] || age > filters[1];
          } else if (filters[0]) {
            return age < filters[0];
          } else if (filters[1]) {
            return age > filters[1];
          }
          return false;
        },
        display: (filterList, onChange, index, column) => (
          <div>
            <FormLabel style={{ fontSize: 15 }}>{t('sum')}</FormLabel>
            <FormGroup row>
              <TextField
                label={t("from_what")}
                type={'number'}
                value={filterList[index][0] || ''}
                onChange={event => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', }}
              />
              <TextField
                label={t("to_what")}
                type={'number'}
                value={filterList[index][1] || ''}
                onChange={event => {
                  filterList[index][1] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                style={{ width: '45%', marginRight: '5%', }}
              />
            </FormGroup>
          </div>
        ),
      },
    }
  },
  {
    name: 'baggageType',
    label: t("baggage_type"),
    options: {
      filter: true,
      sort: true,
      filterType: 'multiselect' as any
    }
  }
];

export const OPTIONS: any =(t:any)=> {
  return {
  print: false,
  download: true,
  filter: true,
  search: true,
  sort: true,
  viewColumns: false,
  selectableRows: 'none',
  downloadOptions: {
    filename: "excel-format.csv",
    separator: ";",
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true
    }
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    return "\uFEFF" + buildHead(columns) + buildBody(data);
  },
  textLabels: {
    body: {
      noMatch: t('noMatch'),
      toolTip: t("sort"),
      columnHeaderTooltip: (column: any) => `${t("sort_by")} ${column.label}`,
    },
    pagination: {
      next: t("next_page"),
      previous: t("previous"),
      rowsPerPage: t("rowsPerPage"),
      displayRows: t("a_from"),
    },
    toolbar: {
      search: t("search"),
      downloadCsv: t("downloadCsv"),
      print: t("print"),
      viewColumns: t("viewColumn"),
      filterTable: t("filterTable"),
    },
    filter: {
      all: t("all"),
      title: t('filters'),
      reset: t("reset"),
    },
    viewColumns: {
      title: t('showColumns'),
      titleAria: t("show_hide_table_columns"),
    },
    selectedRows: {
      text: t("row_selected"),
      delete: t("delete"),
      deleteAria: t("delete_area"),
    },
  }
}
  };

