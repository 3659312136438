import ru from './ru.json';
import en from './en.json';
import fr from './fr.json';
import tr from './tr.json';

export default {
    ru,
    en,
    fr,
    tr
}