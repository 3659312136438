class UserModel {
  readonly userName: string;
  readonly firstName: string;
  readonly secondName: string;
  readonly thirdName: string;
  readonly createdOn: string;
  readonly birthday: string;
  readonly email: string;
  readonly id: string;
  readonly role: string;

  constructor({ userName, firstName, secondName, thirdName, createdOn, birthday, email, id,role }) {
    this.userName = userName;
    this.firstName = firstName;
    this.secondName = secondName;
    this.thirdName = thirdName;
    this.createdOn = createdOn;
    this.birthday = birthday;
    this.email = email;
    this.id = id;
    this.role=role
  }
}

export default UserModel;
