/* eslint-disable import/no-extraneous-dependencies */

import moment from 'moment';
import BlackListModel from 'models/blackList';


type blackListResponseModel = {
  firstName: string,
  secondName: string,
  birthdayTS: number,
  avatarUrl: string,
  reason: string,
  additionDateTS: number,
  email: string,
  phoneNumber: string,
  p2PUserId: string,
  deviceID: string,
  id: string,
  createdOn: string,
  createdOnTS: number
};

const createBlackfromRowData = (responseItem: blackListResponseModel,): any => {
  return new BlackListModel({
    id:responseItem?.id,
    createdOn: moment(responseItem.createdOnTS).format('DD.MM.YYYY HH:mm').toString(),
    firstName: responseItem?.firstName,
    secondName: responseItem?.secondName,
    email: responseItem?.email,
    phoneNumber: responseItem?.phoneNumber,
  });
};

export const createBlackListFromRowData = (
  response: Array<blackListResponseModel>
): Array<BlackListModel> =>
  response.map((item: blackListResponseModel,) => createBlackfromRowData(item));

export default createBlackListFromRowData;
