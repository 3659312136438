/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {  useParams ,useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import Button from '@material-ui/core/Button';
import { COLUMN, OPTIONS, createCarDetail, } from './utils';
import { CarsClient, PlaceDetails, UserClient } from '../../services/api';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Tab, Tabs } from '@material-ui/core';
import { ShifterImages } from 'components/ParcelDetail/ShifterImages';
import UserDealList from 'components/UserComponent/UserDealList/UserDealList';
import { createSortedDealListFromRowData } from 'models/modelFactory/deal';
import { AppContextType, useApp } from 'context/AppContext';
import { getVerificationStatus } from 'models/util';


const CarDetail = function (): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { getCarById,deleteCarById,getDealByCarId} = CarsClient();
  const { getPlaceDetails } = PlaceDetails();
  const { getUserById } = UserClient();
  const [dealList,setDealList]=useState([]);
  const title  = t('detail_car');
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [value, setValue] = React.useState('1');
  const [carPhotos,setCarPhotos]=useState([]);
  const [passportPhotos,setPassportPhotos]=useState([]);
  const [carDetails, setCarDetails] = useState({});
  const { baggages } = useApp() as AppContextType;

  const handleChange = (event: any, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    (async () => {
      const detail = await getCarById(id);
      const userDetail = await getUserById(detail.clientId);
      detail.verificationStatus = t(getVerificationStatus(detail.verificationStatus));
      detail.fio = `${userDetail?.firstName} ${userDetail?.secondName} ${userDetail?.thirdName}`.trim();
      const list = await getDealByCarId(id);
      const parsedData:any = list
        ? createSortedDealListFromRowData(list, baggages)
        : [];
      const locationFromData = await Promise.all(parsedData.map(deal => getPlaceDetails(deal.locationFromPlaceId)));
      const locationToData = await Promise.all(parsedData.map(deal => getPlaceDetails(deal.locationToPlaceId)));
      const localizedData = parsedData.map(deal => {
        const locationFrom = locationFromData.find(item => item.placeId === deal.locationFromPlaceId);
        const locationTo = locationToData.find(item => item.placeId === deal.locationToPlaceId);
        return {
          ...deal,
          addressFrom: locationFrom.name,
          addressTo: locationTo.name,
          transport: t((deal.transport).toLowerCase())
        }
      });
      setData(createCarDetail(detail,t));
      setCarDetails(detail);
      setCarPhotos(detail.carPhotos);
      setPassportPhotos(detail.passportPhotos);
      setDealList(localizedData);
    })();
  }, [t,id,baggages]);


  const deleteData=async ()=>{
    const deleteId = await deleteCarById(id);      
    history.goBack()
  }  
  return (
    <React.Fragment>
      <h1 style={{ textAlign: 'center' }}>{title}</h1>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ width: '40%', marginRight: '2%' }}>
          <MUIDataTable
            title={''}
            data={data}
            columns={COLUMN(t, carDetails)}
            options={OPTIONS(t)}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
          <div style={{ margin: '5px' }}>
            <Button variant='outlined' onClick={deleteData}>
              {t('delete')}
            </Button>
          </div>
          <div style={{ margin: '5px' }}>
            
          </div>
  
        </div>
        <div style={{ width: '100%', }}>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} textColor='primary' indicatorColor='primary'>
            <Tab value='1' label={`${t('car_photo')} (${carPhotos.length})`} />
            <Tab value='2' label={`${t('photo_inspection')} (${passportPhotos.length})`} />
            <Tab value='3' label={`${t('deals')} (${dealList.length})`} />
          </Tabs>
          <TabPanel value='1'>
            <ShifterImages list={carPhotos} />
          </TabPanel>
          <TabPanel value='2'>
            <ShifterImages list={passportPhotos} />
          </TabPanel>
          <TabPanel value='3'>
            <UserDealList dealList={dealList} />
          </TabPanel>
        </TabContext>
      </div>

    </React.Fragment>
  );
};

export default CarDetail;
