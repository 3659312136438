/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-deprecated */

import React, { FC, useCallback, useState } from 'react';
import { useHistory, } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Container, CssBaseline, TextField, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ajaxAction } from '../services';
import { saveCredentials } from '../hooks/useLogin';
import {  useActionApp } from '../context/AppContext';

enum FormFields {
  USER_NAME = 'userName',
  PASSWORD = 'password'
}

type IFormFields = {
  [FormFields.USER_NAME];
  [FormFields.PASSWORD];
};

export const Login: FC = (): JSX.Element => {
  const {t}=useTranslation();
  const [errorText, setErrorText] = useState<string>();
  const history = useHistory();
  const { setIsAuthenticated } = useActionApp();

  const initialValues = {
    [FormFields.USER_NAME]: '',
    [FormFields.PASSWORD]: ''
  };

  const submitForm = useCallback(
    async (values) => {
      const xForm = `grant_type=password&client_id=butler_react_app&client_secret=butler_react_app_secret&username=${encodeURIComponent(
        values[FormFields.USER_NAME]
      )}&password=${encodeURIComponent(values[FormFields.PASSWORD])}`;

      const url = process.env.REACT_APP_HOST_AUTH + '/connect/token';
      const method = 'POST';
      const resp: any = await ajaxAction(url, method, xForm);

      if (resp) {
        const { data, status_code } = resp;
        if (status_code === '200') {
          const { access_token, error_text } = data;
          if (error_text !== undefined) {
            setErrorText(error_text);
            setIsAuthenticated(false)
            return;
          } else if (access_token) {
            saveCredentials(data);
            setIsAuthenticated(true)
            history.push('/');
          }
        } else {
          setIsAuthenticated(false)
          setErrorText('Wrong login or password');
        }
      }
    },
    [history]
  );

  const { handleSubmit, values, handleChange } = useFormik<IFormFields>({
    onSubmit: submitForm,
    initialValues: initialValues,
    validationSchema: yup.object({
      [FormFields.USER_NAME]: yup.string().required('Требуется логин оператора'),
      [FormFields.PASSWORD]: yup.string().required('Требуется пароль')
    })
  });

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      {errorText && (
        <Alert variant='outlined' severity='error'>
          {errorText}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant='outlined'
          margin='normal'
          label={t("login_operator")}
          id={FormFields.USER_NAME}
          name={FormFields.USER_NAME}
          value={values[FormFields.USER_NAME]}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          variant='outlined'
          margin='normal'
          label={t("password_operator")}
          type='password'
          name={FormFields.PASSWORD}
          id={FormFields.PASSWORD}
          value={values[FormFields.PASSWORD]}
          onChange={handleChange}
        />
        <Button type='submit' fullWidth variant='contained' color='primary'>
          {t("signin")}
        </Button>
      </form>
    </Container>
  );
};

export default Login;
