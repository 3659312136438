import React from 'react';
import { styled } from "@mui/material/styles";
import Dialog from '@mui/material/Dialog';

import './accordion.css'
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { title } from 'process';



const DialogComponent = function (props: { 
  handleClose: () => void, open: boolean,
   reason: string,
    setReason: (value: string) => void,
    add: () => void, 
    title: string }): JSX.Element {
  const { t } = useTranslation();



  return (
    <Dialog onClose={props.handleClose} open={props.open} >
      <div style={{
        backgroundColor: "white",
        minHeight: '150px'
        , maxHeight: '700px',
        minWidth: '400px',
        overflow: 'auto', borderRadius: 5, padding: '10px', boxShadow: "1px 15px 8px #9E9E9E"
      }}>

        <div style={{ width: '100%',  display: "flex",justifyContent:"space-between",alignItems:'center' }}>
        <h3>{props.title}</h3>

          <img
            src={'https://icons-for-free.com/download-icon-x-1321215629555778185_512.png'}
            style={{ width: '20px', height: '20px', objectFit: 'contain', marginBottom: '10px' }}
            alt={''}
            onClick={props.handleClose}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <input type="text"
            color='secondary'

            value={props.reason}
            onChange={(event) => { props.setReason(event.target.value) }}
            style={{ padding: 15 }}
          />
          <Button variant='outlined' onClick={props.add}>
            {t('add')}
          </Button>
        </div>


      </div>
    </Dialog>
  );
}
export default DialogComponent;
