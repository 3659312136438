/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import TimezoneSelect, { allTimezones, ITimezone } from 'react-timezone-select';


export function TimeZone(): JSX.Element {
    const tz = moment.tz.guess();
    const timeZone = localStorage.getItem('timeZone')??tz;
    const [value, setValue] = useState(timeZone);

    return (
        <div style={{width:"15%",color:'black'}}>
             <TimezoneSelect
          value={value}
          onChange={(value:any)=>{
            setValue(value.value);
            localStorage.setItem('timeZone', value.value);
            window.location.reload();
          }}
          timezones={{
            ...allTimezones,
            "America/Lima": "Pittsburgh",
            "Europe/Berlin": "Frankfurt"
          }}
        />
        </div>
    );
}
